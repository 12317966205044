import * as React from 'react'
import * as BoxStyles from './Box.module.css'

function Box({ number, heading, children, colour }) {
    return (
        <div className={`${BoxStyles.container} ${BoxStyles[colour]}`}>
            {number && (<span className={BoxStyles.number}>{number}</span>)}
            <h3 className={BoxStyles.heading}>{heading}</h3>
            {children}
        </div>
    )
}

export default Box