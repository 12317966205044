import * as React from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../../components/Layout/Layout'
import Appointments from '../../components/Appointments/Appointments'
import Box from '../../components/Box/Box'
import Button from '../../components/Button/Button'
import LinkGrid from '../../components/LinkGrid/LinkGrid'
import Team from '../../components/Team/Team'
import Text from '../../components/Text/Text'
import Underline from '../../components/Underline/Underline'
import Title from '../../components/Title/Title'
import TxtImg from '../../components/TxtImg/TxtImg'
import Testimonial from '../../components/Testimonial/Testimonial'
import { conditions } from '../../util/data'
import * as OsteopathyStyles from './osteopathy.module.css'

function Osteopathy({ data }) {
    return (
        <Layout>
            <Helmet>
                <title>Osteopathy | Brixworth Osteopathic Clinic</title>
            </Helmet>
            <section className={OsteopathyStyles.title}>
                <Title>
                    <h1>Excellent Osteopathic Care for Patients of all Ages</h1>
                    <h2>We spend the time needed to understand you, the individual, to develop a tailored & well-rounded rehabilitation programme.</h2>
                    <Button external={true} type="neutralOutline" to="https://eubookings.nookal.com/bookings/location/GGNJC">Book online today</Button>
                </Title>
            </section>
            <section className="condense">
                <TxtImg
                    split="half"
                    imgFirst={false}
                    imgSrc={data.osteo1}
                    imgAlt="Osteopath treating a patient"
                >
                    <Underline>
                        <h2>What is Osteopathy?</h2>
                    </Underline>
                    <p>Osteopathy is a method of assessing, treating and preventing a wide range of health problems. Osteopaths use a combination of movement, stretching, targeted deep tissue massage and manipulation of a person’s muscles and joints to improve function, relieve pain and aid recovery.</p>
                    <p>The principle of osteopathy is that the wellbeing of an individual relies on the way that joints, muscles, ligaments, connective tissue, blood supply and other internal structures work with each other whilst taking time to consider the emotional and physical stresses the body may be under in day to day life.</p>
                </TxtImg>
            </section>
            <section>
                <Text>
                    <Underline>
                        <h2>What can Osteopathy Help With?</h2>
                    </Underline>
                    <p>Although osteopaths are well known for treating back pain, the practice of osteopathy can help relieve the symptoms of a wide range of conditions. This includes joint pain, neuromuscular conditions, digestive conditions, headaches, sleep problems and more.</p>
                    <p>Find out more about how osteopathy can help with any of the following conditions:</p>
                </Text>
                <LinkGrid
                    items={conditions}
                />
            </section>
            <section style={{backgroundColor: 'var(--neutral-50)'}}>
                <Text>
                    <Underline>
                        <h2>Meet the Osteopaths</h2>
                    </Underline>
                    <p>The team of osteopaths here at the Brixworth Osteopathic Clinic are highly trained and are focused on helping you find and treat the cause of your symptoms rather than just focusing on the symptoms themselves.</p>
                </Text>
                <Team />
            </section>
            <section>
                <Text>
                    <Underline>
                        <h2>What to Expect at your Appointment</h2>
                    </Underline>
                    <p>When you first visit an osteopath, you might not know what to expect. The initial appointment will look something like this.</p>
                </Text>
                <div className={OsteopathyStyles.boxContainer}>
                    <Box number="1" heading="Case history" colour="light">
                        <p>At the start of your first session we will ask questions about your medical history and lifestyle as well as your symptoms. This is very important as it will help us to make an accurate diagnosis and suggest appropriate treatment.</p>
                        <p>This information is kept confidential in conjunction with the Data Protection Act 2018.</p>
                    </Box>
                    <Box number="2" heading="Examination" colour="light">
                        <p>We will need to examine the area(s) of your body causing discomfort. Sometimes the cause of the problem may be in a different area to the pain, (for example, a difference in leg length may result in compensations in the upper back which might result in neck pain) so we may need to examine your whole body.</p>
                        <p>We will need to feel for tightness in the muscles and stiffness in the joints and may need to touch these areas to identify problems, which will be explained as we go along.</p>
                    </Box>
                    <Box number="3" heading="Treatment" colour="light">
                        <p>Having carried out an examination and gathered the context of your case history will give your osteopath a much better understanding of the likely cause(s) of any pain or injury.</p>
                        <p>It's at this stage that attention can turn to treatment, however before beginning any treatment your osteopath will discuss a treatment plan with you that will consider your initial treatment, rehabilitative exercises and any follow-up appointments that are needed.</p>
                    </Box>
                </div>
                <div className={OsteopathyStyles.modesty}>
                    <Box heading="Your modesty matters" colour="dark">
                        <p>It is important that you feel comfortable during your osteopathic treatment, particularly around matters of modesty and privacy.</p>
                        <p>As with a visit to a GP or other medical professional, for us to examine you effectively it may be necessary for them to ask you to remove some clothing as appropriate for the condition. This may mean undressing down to your underwear. If this is a problem for you then let us know and we will try to make arrangements that make you more comfortable. You are also welcome to bring someone with you for all or part of your consultation, and children should always be accompanied by a parent or guardian.</p>
                        <p>If you feel uncomfortable with any part of the process then let us know. We can discuss this with you or stop if you prefer.</p>
                    </Box>
                </div>
            </section>
            <Testimonial name="James" age="42">
                <p>I'd been struggling with upper back and shoulder pain for a number of year linked to my posture at work - my GP told me it was "just wear and tear" and offered me pain meds.</p>
                <p>After a couple of sessions with Gordon I felt better and understood in much much more detail what might be causing the pain as well as giving me ideas to manage it day to day. I still see Gordon every 2-3 months for a bit of an MOT but things a so much better now.</p>
            </Testimonial>
            <section>
                <Appointments fees="osteopathy" />
            </section>
        </Layout>
    )
}

export const query = graphql`
    query {
        osteo1: file(relativePath: {eq: "osteopathy.jpg"}) {
            childImageSharp {
                gatsbyImageData(
                    width: 800
                    placeholder: BLURRED
                )
            }
        }
    }
`

export default Osteopathy